import React,{useState} from 'react'
import { Link } from "gatsby"
import "./works.style.scss"

const works = require('./works.json')


const Works = () => {

    const [listState, setListState] = useState("1")
    
    const renderItem = works.data.map((item,index) => {
        return (
            <div key={item.id} className="works-items-container">
                <div className="works-time">
                    <p className="time-item-main">
                        {item.month}<br />
                        <span className="time-item-sub">/{item.day}</span>
                    </p>
                </div>
                <div className="works-item">
                    <p className="works-item-title">{item.title}</p>
                </div>
            </div>
        )
    })

    return (
        <section>
             <header  className="header ph content_header">
            <div className="header_logo_box">
                <img className="header_logo img_color" src="https://spin-dd.com/wp-content/uploads/2020/03/spin_logo_w.png" alt="会社のロゴです" />
                <nav className="nav-container">
                    <ul className="ui-container">
                    <li className="nav-item home-visited"><button><Link to ="/">HOME</Link></button></li>
                    <li className="nav-item home-visited"><button><Link to ="/about">ABOUT</Link></button></li>
                    <li className="nav-item home-visited"><button><Link to ="/services">SERVICES</Link></button></li>
                    <li className="nav-item home-visited"><button>WORKS</button></li>
                    <li className="nav-item home-visited"><button>BLOG</button></li>
                    <li className="nav-item home-visited"><button><Link to ="/contact">CONTACT</Link></button></li>
                    </ul>
                </nav>
                </div>
            </header>
                <div className="works-title-container">
                <h2 className="works-title">WORKS</h2>
            </div>
            
            <div className="works-main-container">
                <div className="works-list-left">
                    <p onClick={() => setListState("1")} className={`works-p works-1${listState}`}>ALL</p>
                    <p onClick={() => setListState("2")} className={`works-p works-2${listState}`}>IT</p>
                    <p onClick={() => setListState("3")} className={`works-p works-3${listState}`}>Movie</p>
                </div>
                <div className="works-list-right">
                    {renderItem}
                </div>
            </div>
        </section>
        
    )
}

export default Works;